import {
    DeviceOs,
    DeviceType, Session,
} from "@/model/models";
import apiClient from "@/utils/api-client";

class UserAgentParser {
    public getAppVersion(userAgent: string, browser: DeviceType){
        let versionStartIndex = 0;
        let browserVersion: string;
        let browserIndex: number;

        if (browser === DeviceType.OPERA) {
            if(userAgent.includes("OPR/")){
                browserIndex = userAgent.indexOf("OPR/");
                versionStartIndex = browserIndex + "OPR/".length
            }
            else if (userAgent.includes("Opera")) {
                browserIndex = userAgent.indexOf("Opera/");
                versionStartIndex = browserIndex + "Opera/".length;
            }
            else if (userAgent.includes("Presto")) {
                browserIndex = userAgent.indexOf("Presto/");
                versionStartIndex = browserIndex + "Presto/".length;
            }
          }
        else if (browser === DeviceType.CHROME) {
            if(userAgent.includes("Chrome/")){
                browserIndex = userAgent.indexOf("Chrome/");
                versionStartIndex = browserIndex + "Chrome/".length;
            }
            else if (userAgent.includes("CriOS")) {
                browserIndex = userAgent.indexOf("CriOS/");
                versionStartIndex = browserIndex + "CriOS/".length;
            }
        }
        else if (browser === DeviceType.SAMSUNG_INTERNET) {
            browserIndex = userAgent.indexOf("SamsungBrowser/");//check for other indexes
            if (browserIndex !== -1) {
                versionStartIndex = browserIndex + "SamsungBrowser/".length;
            }
        }
        else if (browser === DeviceType.FIREFOX) {
            if(userAgent.includes("Firefox/")){
                browserIndex = userAgent.indexOf("Firefox/");
                versionStartIndex = browserIndex + "Firefox/".length;
            }
            else if (userAgent.includes("FxiOS/")) {
                const browserIndex = userAgent.indexOf("FxiOS/");
                versionStartIndex = browserIndex + "FxiOS/".length;
            }
            else {return null;}
        }
        else if (browser === DeviceType.SAFARI) {
            browserIndex = userAgent.indexOf("Safari/");
            versionStartIndex = browserIndex + "Safari/".length
        }
        else if (browser === DeviceType.EDGE) {
            if(userAgent.includes("EdgiOS/")){
                browserIndex = userAgent.indexOf("EdgiOS/");
                versionStartIndex = browserIndex + "EdgiOS/".length;
            }
            else if (userAgent.includes("EdgA/")) {
                browserIndex = userAgent.indexOf("EdgA/");
                versionStartIndex = browserIndex + "EdgA/".length;
            }
            else if (userAgent.includes("Edg/")) {
                browserIndex = userAgent.indexOf("Edg/");
                versionStartIndex = browserIndex + "Edg/".length;
            }
            else {return null;}
        }
        else {
            return null;
        }

        const versionSubstring = userAgent.substring(versionStartIndex);
        const versionEndIndex = versionSubstring.search(/[ A-Za-z]/) + versionStartIndex;
        //console.log(versionEndIndex);
        if(versionEndIndex<versionStartIndex){
            browserVersion = userAgent.substring(versionStartIndex)}
        else{
            browserVersion = userAgent.substring(versionStartIndex, versionEndIndex);
        }
        return browserVersion;
    }

    public extractDeviceType(userAgent: string){
        if (userAgent.includes("Edg")) {
            return DeviceType.EDGE;
        } else if(userAgent.includes("Iron") || userAgent.includes("Epic") || userAgent.includes("Falcon") || userAgent.includes("Maxthon") || userAgent.includes("Konqueror") || userAgent.includes("K-Meleon") || userAgent.includes("Pale Moon") || userAgent.includes("Waterfox") || userAgent.includes("Midori") || userAgent.includes("Otter Browser") || userAgent.includes("Qutebrowser") || userAgent.includes("Avant Browser") || userAgent.includes("Dooble") || userAgent.includes("Luakit") || userAgent.includes("Sleipnir") || userAgent.includes("Swiftfox")){
            return DeviceType.OTHER_BROWSER;
        } else if (userAgent.includes("Firefox") || userAgent.includes("FxiOS")) {
            return DeviceType.FIREFOX;
        } else if (userAgent.includes("OPR") || userAgent.includes("Presto") ||  userAgent.includes("Opera")) {
            return DeviceType.OPERA;
        } else if (userAgent.includes("SamsungBrowser")) {
            return DeviceType.SAMSUNG_INTERNET;
        } else if (userAgent.includes("Chrome") || userAgent.includes("CriOS")) {
            return DeviceType.CHROME;
        }else if (!userAgent.includes("OPR") && !userAgent.includes("Edg") && !userAgent.includes("Chrome") && (userAgent.includes('iPhone') || userAgent.includes('iPad') || userAgent.includes('iPod') || userAgent.includes('Macintosh'))) {
            return DeviceType.SAFARI;
        }  else {
            return DeviceType.OTHER_BROWSER;
        }
    }
    public extractDeviceOs(userAgent: string){
        if(userAgent.includes("Windows")){
            return DeviceOs.WINDOWS;//Should we add a new deviceOs named Windows, how to detect if is it from app
        }else if (userAgent.includes('Android')) {
            return DeviceOs.ANDROID;
        } else if (userAgent.includes('iPhone') || userAgent.includes('iPad') || userAgent.includes('iPod')) {
            return DeviceOs.IOS;
        } else if (userAgent.includes('Android TV')) {
            return DeviceOs.ANDROID_TV;
        } else if (userAgent.includes('X11') || userAgent.includes('Linux')) {
            return DeviceOs.LINUX;
        } else if (userAgent.includes('Macintosh')) {
            return DeviceOs.MACOS;
        } else {
            return DeviceOs.OTHER_OS;
        }
    }

    public createSession(vidictId: number, deviceOs?: DeviceOs, deviceType?: DeviceType, appVersion?: string, userAgent?: string) {
        const newSession = new Session(vidictId, deviceOs, deviceType, appVersion, userAgent)
        return newSession;
    }
}
export default new UserAgentParser()
