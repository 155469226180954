import apiClient from '@/utils/api-client'

import {Plan} from "@/model/models";

class PlanService {

    public insertUserPlanRequest(userPlanId: string) {
        return new Promise<boolean>((resolve, reject) => {
            apiClient.get('/api/v1/user/plan/' + userPlanId).then((res) => {
                if (res.status == 200 && res.data != null) {
                    resolve(true)
                }
            }).catch((error) => {
                reject(error)
            })
        })
    }

    public insertTrialUserPlanRequest(userPlanId: string) {
        return new Promise<boolean>((resolve, reject) => {
            apiClient.get('/api/v1/user/plan/trial/' + userPlanId).then((res) => {
                if (res.status == 200 && res.data != null) {
                    resolve(true)
                }
            }).catch((error) => {
                reject(error)
            })
        })
    }

    public updateUserPlanPaymentExecuted() {
        return new Promise<boolean>((resolve, reject) => {
            apiClient.get('/api/v1/user/plan/payment').then((res) => {
                if (res.status == 200 && res.data != null) {
                    resolve(true)
                }
            }).catch((error) => {
                reject(error)
            })
        })
    }

    public validateTrialActivationCode(trialCode: string) {
        return new Promise<boolean>((resolve, reject) => {
            apiClient.get('/api/v1/user/plan/validate/' + trialCode).then((res) => {
                if (res.status == 200 && res.data == true) {
                    resolve(true)
                } else {
                    resolve(false)
                }
            }).catch((error) => {
                reject(error)
            })
        })
    }

}

export default new PlanService()
