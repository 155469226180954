<template>
  <Header></Header>
  <div class="main-height" v-show="showPassword">
      <section class="second-page">
      <div class="container">
          <div class="inner-container">
              <div class="row">
                  <div class="col-12">
                      <p class="mb-0">
                          Step <span class="fw-bold">1</span> of
                          <span class="fw-bold">3</span>
                      </p>
                  </div>
              </div>
              <div class="row">
                  <div class="col-12">
                      <h1 class="fw-bold">
                          Create a password to watch on any device at any time
                      </h1>
                  </div>
              </div>
              <div class="row">
                  <div class="col-12">
                      <p>
                          Create an account and we'll send you an email with everything
                          you need to know about ProCricket.
                      </p>
                  </div>
              </div>
              <div class="row">
                  <div class="col-12 mt-2">
                      <form>
                          <div class="register-form">
                              <div class="input-group">
                                  <input required="" type="text" name="email"  class="input-default" :value="email" readonly/>
                                  <label class="user-label-default">Email</label>
                              </div>

                              <div class="input-group mt-2">
                                  <input required="" type="password" name="password"  class="input" v-model="password" @keyup.enter="doSubmitPassword"/>
                                  <label class="user-label">Password</label>
                              </div>
                          </div>
                          <div v-if="error.length" class="alert-danger mt-3 pe-3 text-danger">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                              class="bi bi-exclamation-circle me-1 mb-1" viewBox="0 0 16 16">
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                              <path
                                d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                            </svg>
                            {{ error }}
                          </div>
                          <div class="secondpage-checkbox mt-2">
                              <div class="d-flex">
                                  <input class="form-check-input check-btn rounded-0" type="checkbox" value=""
                                  id="flexCheckDefault" v-model="checked"/>
                                  <label class="form-check-label ms-1" for="flexCheckDefault">Please do not email me special offers.</label>
                              </div>
                          </div>
                      </form>
                  </div>
              </div>
              <div class="row">
                  <div class="col-12 mt-3">
                      <div class="text-center">
                          <button class="btn text-center text-white w-100 rounded-1" @click="doSubmitPassword">
                            <span v-show="!loading">Next</span>
                            <div v-show="loading" class="button-loading">
                              <div class="spinner-border" role="status">
                                <span class="sr-only"></span>
                              </div>
                            </div>
                          </button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
  </div>

  <Footer></Footer>
  

</template>
  
<script>
import {defineComponent} from 'vue'
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import UsersService from '@/services/users-service'
import AuthService from '@/services/auth-service'
import PlanService from '@/services/plan-service'
import {SignupRequest} from "@/model/models";
import UserAgentParser from "@/utils/user-agent-parser";

export default defineComponent({
name: 'Register',
components: {
  Header,
  Footer
  
},
data() {
  return {
    showEmail: true,
    showPassword: true,
    email: '',
    password: '',
    error: '',
    checked: '',
    loading: false
  }
},
methods: {

  doSubmitPassword() {
    if (this.loading) return;
    this.loading = true;

    if (this.password === '') {
      this.error = 'Please enter password';
      this.loading = false;
    } else if (this.password.length < 6) {
      this.error = 'Password must have at least 6 characters';
      this.loading = false;
    } else {
      UsersService.signupUserRequest(new SignupRequest(this.email, this.password, this.checked != '')).then((valid) => {
        if (valid) {
          this.doSignIn()
        } else {
          this.error = 'Invalid registration';
          this.loading = false;
        }
      }).catch(() => {
        this.error = 'Invalid registration';
        this.loading = false;
      })
    }
  },
  doSignIn() {
    AuthService.signIn(this.email, this.password).then(() => {
      UsersService.getUserStaticData().then(() => {
        UsersService.getUserProfile().then(() => {
          this.$router.push('/plans')
        }).catch(() => {
          this.error = 'Login failed';
          this.loading = false;
        })
      }).catch(() => {
        this.error = 'Login failed';
        this.loading = false;
      })
    }).catch(() => {
      this.error = 'Login failed';
      this.loading = false;
    })
  }
},
mounted () {
  if (this.isSignedIn) {
    this.$router.push('/events')
  }
},
beforeMount() {
  if (localStorage.getItem('email')) {
    this.email = localStorage.getItem('email');
  } else {
    this.$router.push('/')
  }
},
beforeUnmount() {
  localStorage.removeItem('email')
},
computed: {
  isSignedIn () {
    return AuthService.isSignedIn()
  }
}
});
</script>
  
<style>
  @import '../assets/style.css';
</style>